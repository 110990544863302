import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function SuccessModal({ isSuccess, setSuccess }) {
    return (
        <>
            <Overlay
                className={isSuccess ? "active" : ""}
                onClick={() => setSuccess(!isSuccess)}
            ></Overlay>
            <Container className={isSuccess ? "active" : ""}>
                <FormContainer>
                    <TopHead>
                        <Left>
                            <Img>
                                <img
                                    src={
                                        require("../../assets/images/tick.svg")
                                            .default
                                    }
                                    alt="icon"
                                />
                            </Img>
                            <H5>Successful</H5>
                        </Left>
                    </TopHead>
                    <Bottom>
                        <Description>
                            Your application is successfully submitted. Our
                            executive will get back to you.
                            <br />
                            <br />
                            Thank you!
                        </Description>
                        <ButtonConatiner>
                            <Submit
                                to="/"
                                onClick={() => {
                                    setSuccess(false);
                                }}
                            >
                                Go to Home
                            </Submit>
                        </ButtonConatiner>
                    </Bottom>
                </FormContainer>
            </Container>
        </>
    );
}

const Left = styled.div`
    display: flex;
    align-items: center;
    grid-gap: 15px;
`;
const Img = styled.div`
    width: 45px;
    height: 45px;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 640px) {
        width: 37px;
        height: 37px;
    }
    @media all and (max-width: 640px) {
        width: 30px;
        height: 30px;
    }
`;
const Container = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: ease-in-out 0.2s;

    &.active {
        opacity: 1;
        visibility: visible;
    }
`;
const Overlay = styled.div`
    cursor: pointer;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.5);
    &.active {
        display: block;
    }
`;
const FormContainer = styled.div`
    position: fixed;
    z-index: 1001;
    transform: scale(0);
    width: 600px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 31px 40px 45px 40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    overflow: hidden;

    &.active {
        transform: scale(1);
    }
    @media all and (max-width: 768px) {
        width: 600px;
    }
    @media all and (max-width: 640px) {
        width: 85%;
        padding: 25px 25px;
    }
    @media all and (max-width: 480px) {
        width: 91%;
        padding: 31px 20px 31px 20px;
    }
`;
const TopHead = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    margin-bottom: 25px;
    @media all and (max-width: 480px) {
        padding-bottom: 15px;
        margin-bottom: 16px;
    }
`;
const H5 = styled.h5`
    font-size: 21px;

    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;

const Bottom = styled.div``;

const Description = styled.p`
    color: #6c6c6c;
    font-size: 16px;
    margin-bottom: 56px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    & b {
        color: #4ca473;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 26px;
        width: 90%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 18px;
    }
`;

const ButtonConatiner = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;
const Submit = styled(Link)`
    padding: 12px 26px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background: #9f51b0;
    border-radius: 5px;
    font-size: 16px;
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
