import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { Context } from "../context/Store";

export default function CountrySelector({
  handleClick,
  show,
  onSelectHandler,
  selectedCountry,
  selected,
  setSelected,
}) {
  const { state, dispatch } = useContext(Context);
  //outside click
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClick();
          setSearchTerm("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  //search functions
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [countryDetails, setCountryDetails] = useState([]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const results = countryDetails.filter(
      (item) =>
        item.name.startsWith(searchTerm) ||
        item.name.toLowerCase().startsWith(searchTerm) ||
        item.name.toUpperCase().startsWith(searchTerm)
    );

    const contains = countryDetails.filter((item) =>
      item.name.toLowerCase().includes(searchTerm)
    );
    const finalArray = results.concat(contains);

    setSearchResults([...new Set(finalArray)]);
  }, [searchTerm, countryDetails]);

  useEffect(() => {
    const fetchCountries = () => {
      axios
        .get("https://api.thefemme.org/api/v1/general/countries/")
        .then((response) => {
          let { StatusCode, data } = response.data;
          if (StatusCode === 6000) {
            setCountryDetails(data);
          }
        });
    };
    fetchCountries();
  }, []);

  const renderCountries = searchResults.map((item, index) => (
    <CountryItem
      key={index}
      onClick={() => {
        handleClick();
        onSelectHandler(item);
        setSearchTerm("");
        setSelected(item);
        dispatch({
          type: "UPDATE_COUNTRY_DATA",
          selected_country: {
            phone_code: item.phone_code,
            name: item.name,
            flag: item.flag,
            web_code: item.web_code,
            country_code: item.country_code,
          },
        });

        // setShow((prev) => !prev);
        // handleClick()
      }}
    >
      <Left>
        <img src={item.flag} alt="img" />
      </Left>
      <div>
        <h5>{item.name}</h5>
      </div>
    </CountryItem>
  ));

  return show ? (
    <Container>
      <ItemContainer ref={wrapperRef}>
        <SearchContainer>
          <SearchIcon src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/auth/search.svg" />
          <SearchInput
            autoFocus
            className="b-medium"
            placeholder="Select your Country"
            value={searchTerm}
            onChange={handleChange}
          />
        </SearchContainer>
        <CountryList>
          {searchResults.length === 0
            ? countryDetails.map((item) => (
                <CountryItem
                  key={item.name}
                  onClick={() => {
                    onSelectHandler(item);
                    setSearchTerm("");
                  }}
                >
                  <Left>
                    <img src={item.flag} alt="img" />
                  </Left>
                  <div>
                    <h5>{item.name}</h5>
                  </div>
                </CountryItem>
              ))
            : searchResults.length !== 0
            ? renderCountries
            : "No results"}
        </CountryList>
      </ItemContainer>
    </Container>
  ) : null;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;
const ItemContainer = styled.div`
  background: #fff;
  border-radius: 7px;
  width: 327px;
  padding: 28px 21px 10px;
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 15px;
  border: 1px solid #dae3ed;
  border-radius: 6px;
  overflow: hidden;
`;
const SearchIcon = styled.img`
  display: block;
  width: 18px;
  margin-right: 13px;
`;
const SearchInput = styled.input`
  font-size: 17px;
  caret-color: #43c883;
  color: #000;
`;
const CountryList = styled.div`
  padding: 11px 0;
  overflow-y: scroll;
  max-height: 450px;
  min-height: 450px;
  &::-webkit-scrollbar {
    width: 0;
  }
`;
const NoResults = styled.span`
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #9e9e9e;
`;
const CountryItem = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px 12px;
  cursor: pointer;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  width: 30px;
  margin-right: 15px;
  img {
    width: 100%;
    display: block;
  }
`;
const Flag = styled.img`
  display: block;
  width: 37px;
  margin-right: 18px;
`;
const Text = styled.span`
  display: block;
  font-size: 17px;
  /* color: #42c870; */
`;
const Checked = styled.img`
  width: 17px;
`;
