import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_data: {
        is_verified: false,
        country: "IN",
        user_token: {},
        name: "",
        phone: "",
    },
    selected_country: {
        country_code: "",
        flag: "",
        name: "",
        phone_code: "+91",
        web_code: "",
        phone_number_length: "10",
    },
};
const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};
export const Context = createContext(initialState);

export default Store;
